import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, type ResolveFn } from '@angular/router';
import { tap } from 'rxjs';

import { GraphQlResponse, GraphqlService } from '../../shared/services/graphql/graphql.service';
import { CardDTO } from '../../shared/models/cards.model';

export const cardDetailsResolver: ResolveFn<GraphQlResponse | null> = (route, state) => {
  const cardId = route.paramMap.get('cardId');
  const step = route.queryParamMap.get('step');

  if (cardId == 'create') {
    return null;
  }

  const query = `{
    searchCard(id: "${cardId}"){
      templateId
      sections{
        dishSectionCards {
          id
        }
      }
    }
  }`;

  const destroyRef = inject(DestroyRef);
  const router = inject(Router);

  return inject(GraphqlService)
    .get(query)
    .pipe(
      takeUntilDestroyed(destroyRef),
      tap((res) => {
        const card: CardDTO = res.data.searchCard;

        if (
          (step != '0' && step != '1' && step != '2') ||
          (step == '2' && !card.templateId) // || (step == '3' && (!card.sections?.length || card.sections.some((section) => !section.dishSectionCards?.length)))
        ) {
          router.navigate(['cards', cardId], { queryParams: { step: '0' } });
        }
      }),
    );
};
