import { Routes } from '@angular/router';

import { LayoutComponent } from './core/components/layout/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { cardDetailsResolver } from './core/resolvers/card-details.resolver';

export const ROOT_ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        data: { icon: 'crm crm-dashboard-linear', i18n: 'DASHBOARD.TITLE' },
        loadComponent: () => import('./features/dashboard/dashboard.component'),
      },
      {
        path: 'dishes',
        data: { icon: 'cd icon-dishes', i18n: 'DISH.TITLE' },
        loadComponent: () => import('./features/dishes/pages/dishes-list/dishes-list.component')
      },
      {
        path: 'cards',
        data: { icon: 'cd icon-cards', i18n: 'CARD.TITLE' },
        children: [
          {path: 'list', pathMatch: 'full', loadComponent: () => import('./features/cards/pages/cards-list/cards-list.component')},
          {path: ':cardId', resolve: { card: cardDetailsResolver }, loadComponent: () => import('./features/cards/pages/card-details/card-details.component')},
          {
            path: '**',
            redirectTo: 'list',
            pathMatch: 'full',
          },
        ]
      },
      {
        path: 'slugs',
        data: { icon: 'crm crm-calendar', i18n: 'CHANNEL.TITLE' },
        children: [
          {path: 'list', pathMatch: 'full', loadComponent: () => import('./features/slugs/pages/slug-list/slug-list.component')},
          {path: ':slugId', loadComponent: () => import('./features/slugs/pages/slug-details/slug-details.component')},
          {
            path: '**',
            redirectTo: 'list',
            pathMatch: 'full',
          },
        ]

      },
      {
        path: 'qr',
        data: { icon: 'cd icon-qr', i18n: 'QR.TITLE' },
        loadComponent: () => import('./features/qr/pages/principal/principal.component')
      },
      {
        path: 'restaurant',
        data: { icon: 'cd icon-restaurant-manage', i18n: 'RESTAURANT.TITLE' },
        loadComponent: () => import('./features/restaurant/pages/restaurant-details/restaurant-details.component'),
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
];
